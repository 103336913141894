import React, { useState } from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

const Swagger = props => {
  const [versionIndex, setVersionIndex] = useState(0);
  const versioned = 'versions' in props;

  if (typeof window === 'undefined') return null;

  // Passing a dumby query param to the endpoint.
  // The param will be ignored but is set to notify Swagger of a url change, thus refreshing the docs.
  const propsModified = {
    docExpansion: props.docExpansion,
    url: versioned ? `${props.url}?v=${props.versions[versionIndex]}` : props.url,
    requestInterceptor: requestInterceptorVersioning
  };

  // If we are handling versions, we want to pass a header to specify that version.
  function requestInterceptorVersioning(req) {
    if (versioned) {
      req.headers.Accept = `application/vnd.${props.acceptSlug}.v${props.versions[versionIndex]}+json`;
    }
    return req;
  }

  return (
    <div>
      {versioned &&
        <div>
          <h5>This API offers multiple versions. Use the dropdown to cycle through the supported versions.</h5>
          <select onChange={(event) => { setVersionIndex(event.target.value); }}>
            {props.versions.map((name, index) => {
              return (<option key={name} value={index}>v{name}</option>);
            })}
          </select>
        </div>}

      <SwaggerUI {...propsModified} />
    </div>
  )
}

export default Swagger;