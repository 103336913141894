const schemaMap = {
  'tasking': {
    path: process.env.GATSBY_API_ROUTE_SCHEMA_TASKING
  },
  'access-entitlement': {
    path: process.env.GATSBY_API_ROUTE_SCHEMA_ACCESSENTITLEMENT
  },
  'data-access': {
    path: process.env.GATSBY_API_ROUTE_SCHEMA_DATAACCESS
  },
  'catalog': {
    path: process.env.GATSBY_API_ROUTE_SCHEMA_CATALOG
  },
  'analytics': {
    path: process.env.GATSBY_API_ROUTE_SCHEMA_ANALYTICS
  },
  'aois': {
    // TODO: No one has a current .env and it's unclear how these get defined on Gitlab builds, hardcoding for now.
    path: '/aois/docs'
  }
}

export function schemaEndpointByName(name, host = process.env.GATSBY_API_ENDPOINT) {
  const schema = schemaMap[name];
  if (!schema) {
    throw new Error(`Can not find schema name ${name}`);
  }
  return `${host}${schema.path}`
}